.backimage {
    height: 100%;
    background-image: url('../images/home_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.h-h {
    height: 100vh;
}

.company_logo {
    height: 100px;
}

.smartphone {
    position: absolute;
    width: 400px;
    height: 660px;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
    top: 3%;
    right: 50%;
}


/* The horizontal line on the top of the device */

.smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
}


/* The circle on the bottom of the device */

.smartphone:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
}


/* The screen (or content) of the device */

.smartphone .content {
    width: 100%;
    height: 100%;
    background: white;
}

.qr {
    width: 200px;
    height: 200px;
    position: fixed;
    right: 50px;
    /* top: 50px; */
    top: 16px;
    font-size: 12px;
}

.qr img {
    box-shadow: rgb(50 50 65 / 25%) 0px 0px 1px, rgb(50 50 65 / 15%) 0px 12px 24px;
    border-radius: 10px;
}

.description {
    width: 350px;
    position: absolute;
    left: 52%;
    /* top:35%; */
    top: 15%;
}

.description img {
    /* border: 1px solid rgba(121, 121, 131, 0.16); */
    background: white;
    border-radius: 10px;
    padding: 20px;
    width: 230px;
    box-shadow: rgb(50 50 65 / 25%) 0px 0px 1px, rgb(50 50 65 / 15%) 0px 12px 24px;
}

.description p {
    /* font-weight: 300; */
    line-height: 22px;
    color: #797979;
    /* margin-bottom: 50px; */
}

.description h1 {
    color: #313131;
}

.notes_dash li {
    padding: 5px;
}

.unit {
    padding: 0px !important;
}

.ath-container.ath-icon:before {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    float: none;
    background-color: #eeeeee;
}

.ath-application-icon {
    width: 50px !important;
    height: auto !important;
    margin-bottom: 15px;
}

.buttons div {
    padding: 10px;
    text-align: center;
    line-height: 1;
    color: #fff;
    border-color: #5e72e4;
    background-color: #000000;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    margin: 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.append_label {
    box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
    font-weight: 400;
    line-height: 1.5;
    display: block;
    width: 58%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    transition: all .15s cubic-bezier(.68, -.55, .265, 1.55);
    color: #8898aa;
    margin: 0;
    background: #f5f5f5;
}


/* #bp_sv {
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
} */

.link {
    width: 100%;
    text-align: left !important;
}

h4.count {
    font-size: 20px;
    margin: 0;
}

.grid {
    display: grid;
}

.floatdoc {
    position: fixed;
    bottom: 65px;
    z-index: 99999;
    padding: 10px;
    border-radius: 50px;
    background: #005284;
    box-shadow: 0px 0px 3px 1px #9e9e9e;
    color: #fff;
    right: 14px;
}

.canvasMap {
    width: 100%;
}

.tab-content>.active {
    padding: 16px;
    background: #f4f4f4;
}

.card.active {
    background-color: #005284;
    color: #ffffff;
}

.draft-btn {
    background: #a1a0a0 !important;
}

nav.nav.nav-tabs a {
    width: 50%;
    text-align: center;
    font-size: 16px;
    padding: 10px;
}
nav.archived.nav.nav-tabs a{
    display: flex;
    width: 20%;
    justify-content: center;

}
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
}

.area {
    padding: 10px;
    background: #005284;
    color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.area h4 {
    font-weight: 400;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-top {
    padding: 15px 0;
    background-color: #f5f5f5;
}

.action-top p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.card.read {
    filter: sepia(1);
}

.unit_assign {
    padding: 7px 10px !important;
    background: #e1ebee;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.bg-green {
    background-color: #4CAF50 !important;
}

.steps {
    display: flex;
}

.step {
    height: 5px;
    background: #d3d3d3;
    margin: 4px;
    border-radius: 10px;
}

.active.step,
.done.step {
    background-color: #005284
}

.step-btn button {
    padding: 6px 16px !important;
    color: #fff;
    text-decoration: none;
    background: #005284;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.index {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    color: #3e98c7;
}

.step-btn {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(192, 192, 192, 0.74);
    padding: 10px;
}

.embed iframe {
    width: 100%;
}

.marRight-5 {
    margin-right: -5px;
}

.marLeft-5 {
    margin-left: -5px;
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.group {
    position: relative !important;
    opacity: 1 !important;
    pointer-events: unset !important;
}

nav {
    background: #fff !important;
}

.menu {
    width: 250px;
    left: -1px !important;
}

.menu ul {
    padding: 0;
    margin: 0;
}

.menu ul li {
    font-size: 16px;
    transition: all 0.25s ease;
    animation: fadeInRight .25s ease forwards;
}

.menu ul li a {
    color: #fff;
    display: block;
    padding: 10px 20px;
}

.az-color {
    background: #000 !important;
}

.cam-header {
    background-color: #000;
    display: flex;
    padding: 10px;
}

.cam-footer {
    background-color: #000;
    display: flex;
    padding: 10px;
}

.cam-video {
    display: flex;
}

.cam-header i {
    color: #fff;
}

.letter {
    background: #005284;
    color: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-panel {
    display: flex;
    align-items: center;
}

.pad_z {
    padding: 0 !important;
}

.trans {
    transition: cubic-bezier(0.86, 0, 0.07, 1);
}

.size-text {
    font-size: 20px;
    margin: 0;
}

.card-content .row {
    margin: 0;
}

.form-box {
    box-shadow: 0px 0px 10px 3px #e1e1e1;
    padding: 20px !important;
    margin: 0px 15px !important;
    background: #fff;
}

.horizontal {
    margin: 0;
    margin-bottom: 30px;
    padding: 10px;
}

.p-20 {
    padding: 20px;
    ;
}

.font-p {
    font-size: 30px;
    text-align: right;
    margin: 0;
}

.f-d-c {
    display: flex;
    flex-direction: column;
}

.large {
    width: 100%;
}

.about h2 {
    font-size: 20px;
    text-align: justify;
}

.about {
    text-align: justify;
}

.account p {
    font-size: 16px;
    font-weight: 600;
}

.account h2 {
    font-size: 20px;
    font-weight: 600;
    color: #005284;
}

.flex {
    display: flex;
    width: 100%;
}

.mZero {
    margin: 0;
}

.align-item-center {
    display: flex;
    align-items: center;
}

.justity-center {
    justify-content: center;
}

.align-space-between {
    align-items: center;
    justify-content: space-between;
}

.loading {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999999;
}

.sigCanvas {
    border: 1px solid #d4d4d4;
}

.sound-wave {
    height: 100px;
    width: 300px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #ffffffd1;
}

.flex-end {
    justify-content: flex-end;
}

.padB15 {
    padding-bottom: 15px;
}

.padZero {
    padding: 0 !important;
}

.padLeft15 {
    padding-left: 15px;
}

.padRight15 {
    padding-right: 15px;
}

.mar10 {
    margin: 10px;
}

.collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
}

.SlideIn-appear {
    transform: translateX(30px);
    opacity: 0;
}

.SlideIn-appear.SlideIn-appear-active {
    opacity: 1;
    transform: translateX(0);
    ;
    transition: all 0.6s linear;
}

.SlideIn-enter {
    opacity: 0;
    transform: translateX(30px);
}

.SlideIn-enter.SlideIn-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.2s linear 0.4s;
}

.SlideIn-leave {
    opacity: 1.0;
    transform: translateX(0);
}

.SlideIn-leave.SlideIn-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateX(-30px);
    transition: all 0.2s linear;
}

.mb-40 {
    margin-bottom: 40px;
}

.bg-f5 {
    background: #f5f5f5;
}

.notify {
    position: relative;
    top: -16px;
    left: 2px;
    font-size: 12px;
    font-weight: bold;
}

button.icon {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    background: #232833;
    height: 50px;
    width: 50px;
    color: #4f5b66;
    font-size: 10pt;
    -webkit-transition: all .55s ease;
    -moz-transition: all .55s ease;
    -ms-transition: all .55s ease;
    -o-transition: all .55s ease;
    transition: all .55s ease;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}

@media only screen and (max-width: 500px) {
    .size-text {
        font-size: 15px;
    }
    .topic {
        font-size: 10px;
    }
}